<template>
  <div v-if="!error"></div>
  <div class="center" v-else>
    <p>
      {{ error_description }}
    </p>

    <a :href="getBackURL">Back to Login</a>
  </div>
</template>
<script>
import router from "../router";
import store from "@/store/stores";
import { mapActions } from "vuex";

export async function getToken() {
  const url = window.location.href;
  return await router.app.$pkce
    .exchangeForAccessToken(url)
    .then(resp => {
      if (resp.error) {
        console.error(resp.error);
        window.location.replace(router.app.$pkce.authorizeUrl());
      } else {
        store.commit("userStore/setTokens", resp);
      }

      return resp;
    })
    .catch(err => {
      console.error(err);
      window.location.replace(router.app.$pkce.authorizeUrl());
    });
}

export default {
  name: "Auth",

  data() {
    return {
      error: false,
      error_description: null
    };
  },

  async mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    const state = urlParams.get("state");
    const error = urlParams.get("error");
    const error_description = urlParams.get("error_description");

    if (code && state) {
      if (state === window.sessionStorage.getItem("pkce_state")) {
        window.localStorage.removeItem("token");
        await getToken();
        this.$nextTick(async () => {
          const lastPage = JSON.parse(window.sessionStorage.getItem("lastPage"));
          const redirectData = lastPage
            ? { name: lastPage.name, params: lastPage.params, query: lastPage.query }
            : { name: "Home" };
          await this.$router.replace(redirectData);
        });
      }
    } else if (state && error && error_description) {
      console.error("error", error_description);
      this.error = true;
      this.error_description = error_description;
    }
  },
  methods: {
    ...mapActions("userStore", ["getUserDetails"])
  },
  computed: {
    getBackURL() {
      return process.env.VUE_APP_AUTH_URL + "/home";
    }
  }
};
</script>

<style scoped lang="scss">
.center {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
}
</style>
